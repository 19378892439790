html {
    height: 100%;
    min-height: 100%;
}

body {
    font-family: 'Open Sans', sans-serif;
    /*    font-size: 16px;*/
    font-weight: 300;
    color: #fff;
    line-height: 30px;
    text-align: center;
    /* 	background-image: url(../assets/img/backgrounds/2.jpg); */
    /* background-position: 50%; */
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
}

strong {
    font-weight: 500;
}

a, a:hover, a:focus {
    color: black;
    text-decoration: none;
    -o-transition: all .3s;
    -moz-transition: all .3s;
    -webkit-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

h1, h2 {
    margin-top: 10px;
    font-size: 36px;
    font-weight: 100;
    color: #fff;
    line-height: 50px;
}

h3 {
    font-size: 20px;
    font-weight: 300;
    color: #fff;
    line-height: 30px;
}

h4 {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    line-height: 30px;
}

img {
    max-width: 100%;
}

.medium-paragraph {
    font-size: 18px;
    line-height: 32px;
}

.blue {
    color: #19b9e7;
}

::-moz-selection {
    background: #19b9e7;
    color: #fff;
    text-shadow: none;
}

::selection {
    background: #19b9e7;
    color: #fff;
    text-shadow: none;
}

.btn-link-1 {
    display: inline-block;
    height: 50px;
    margin: 0 5px;
    padding: 16px 20px 0 20px;
    background: #19b9e7;
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
    color: #fff;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.btn-link-1:hover, .btn-link-1:focus, .btn-link-1:active {
    outline: 0;
    opacity: 0.6;
    color: #fff;
}

.btn-link-2 {
    display: inline-block;
    height: 50px;
    margin: 0 5px;
    padding: 15px 20px 0 20px;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid #fff;
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
    color: #fff;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.btn-link-2:hover, .btn-link-2:focus, .btn-link-2:active, .btn-link-2:active:focus {
    outline: 0;
    opacity: 0.6;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
}

/***** Top menu *****/

/***** Top content *****/
.inner-bg {
    padding: 30px 0 150px 0;
}

.top-content .text {
    padding-bottom: 20px;
    color: #fff;
}

.top-content .text h1 {
    color: #fff;
}

.top-content .description {
    margin: 20px 0 10px 0;
}

.top-content .description p {
    opacity: 0.8;
}

.top-content .description a {
    color: #fff;
}

.top-content .description a:hover, .top-content .description a:focus {
    border-bottom: 1px dotted #fff;
}

.top-content .top-big-link {
    margin-top: 35px;
}

.top-content .book img {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.top-content .form-box {
    padding-top: 20px;
}

.form-top {
    overflow: hidden;
    padding: 0 25px 15px 25px;
    -moz-border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    text-align: left;
}

.form-top-left {
    float: left;
    width: 75%;
    padding-top: 25px;
}

.form-top-left h3 {
    margin-top: 0;
    color: #fff;
}

.form-top-left p {
    opacity: 0.8;
    color: #fff;
}

.form-background {
    background: #444;
    background: rgba(0, 0, 0, 0.35);
    margin-bottom: 120px;
}

.form-top-right {
    float: left;
    width: 25%;
    padding-top: 5px;
    font-size: 66px;
    color: #fff;
    line-height: 100px;
    text-align: right;
    opacity: 0.3;
}

.form-bottom {
    padding: 25px 30px 30px 30px;
    -moz-border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
    text-align: left;
}

.form-bottom form textarea {
    height: 100px;
}

.form-bottom form .input-error {
    border-color: #19b9e7;
}

.history_item {
    background-color: rgba(0, 0, 0, 0.50);
    padding-bottom: 15px;
    margin-top: 15px;
    padding-top: 5px;
}

.chat_item {
    margin-top: 4px;
}

.history_title {
    float: left;
    text-align: left;
    color: white;
    font-size: 1.25em;
}

.menucircle {
    width: 84px;
    height: 84px;
    background: red;
    -moz-border-radius: 42px;
    -webkit-border-radius: 42px;
    border-radius: 42px;
}

.menucircle:hover, .menucircle:focus, .menucircle:active, .menucircle:active:focus {
    outline: 0;
    opacity: .99;
    background: rgba(167, 38, 36, .9);
    color: #fff;
    -webkit-transition: all 350ms ease;
    -moz-transition: all 350ms ease;
    -o-transition: all 350ms ease;
}

.messagecircle {
    width: 62px;
    height: 62px;
    background: red;
    -moz-border-radius: 31px;
    -webkit-border-radius: 31px;
    border-radius: 31px;
}

.kachel_big {
    background-color: transparent;
    font-size: large;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-left: 2%;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 10px;
    float: left;
}

.kachel_small {
    background-color: transparent;
    font-size: large;
    color: #000000;
    margin-left: 2%;
    margin-right: 1%;
    margin-top: 10px;
    margin-bottom: 10px;
    float: left;
}

.kachel_big_allow_hover {

}

.kachel_small_allow_hover {

}

.kachel_big_allow_hover:hover {
    background-color: transparent;
    color: white;
}

.kachel_small_allow_hover:hover {
    background-color: transparent;
    color: white;
}

/***** Media queries *****/
@media ( min-width: 992px) {
    .kachel_small {
        width: 22%;
        padding-top: 20px;
        padding-bottom: 10px;
        border-radius: 50px;
    }

    .kachel_big {
        width: 22%;
        margin-right: 1%;
        padding-top: 20px;
        padding-bottom: 10px;
        border-radius: 50px;
    }
}

@media ( min-width: 992px) and (max-width: 1199px) {
}

@media ( min-width: 768px) and (max-width: 991px) {
    .kachel_small {
        width: 30%;
        padding-top: 20px;
        padding-bottom: 10px;
        border-radius: 40px;
    }

    .kachel_big {
        width: 30%;
        margin-right: 1%;
        padding-top: 20px;
        padding-bottom: 10px;
        border-radius: 40px;
    }
}

@media ( min-width: 416px) and (max-width: 767px) {
    .kachel_small {
        width: 46%;
        padding-top: 20px;
        padding-bottom: 10px;
        border-radius: 30px;
    }

    .kachel_big {
        width: 46%;
        margin-right: 1%;
        padding-top: 20px;
        padding-bottom: 10px;
        border-radius: 30px;
    }

    .inner-bg {
        padding: 30px 0 110px 0;
    }

    .top-content .top-big-link {
        margin-top: 25px;
    }

    .top-content .top-big-link a.btn {
        margin-top: 10px;
    }

    .top-content .form-box {
        padding-top: 20px;
    }
}

/* screw writing importants and shit, just stick it in max width since these classes are not shared between sizes */
@media ( max-width: 767px) {
    .navbar-brand {
        font-size: 1.1em;
        max-width: 81%;
        padding: 9px 9px;
    }
}

@media ( max-width: 415px) {
    .kachel_big {
        width: 95%;
        margin-right: 2%;
        padding-top: 10px;
        padding-bottom: 2px;
        font-size: 1em;
        border-radius: 40px;
    }

    .kachel_small {
        width: 46%;
        padding-top: 10px;
        padding-bottom: 2px;
        font-size: 1em;
        border-radius: 25px;
    }

    h1, h2 {
        font-size: 32px;
    }
}

/* Retina-ize images/icons */
@media only screen and (-webkit-min-device-pixel-ratio: 2) , only screen and (
min--moz-device-pixel-ratio: 2) , only screen and (
-o-min-device-pixel-ratio: 2/1) , only screen and (
min-device-pixel-ratio: 2) , only screen and ( min-resolution: 192dpi)
, only screen and ( min-resolution: 2dppx) {
}

.history_bar {
    background-color: rgba(255, 255, 255, 0.6);
    font-size: small;
    padding-top: 25px;
    padding-bottom: 35px;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 95%;
    float: left;
}

.kachel-icon {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 3em;
    cursor: pointer;
}

.kachel-table {
    padding-bottom: 20px;
}

.kachel_grau {

}

.kachel_aktiv {

}

.menulink {
    margin-top: 15px;
    height: 65px;
    line-height: normal;
    margin-left: 8px;
    margin-right: 8px;
}

.flag_big {
    height: 16px;
    width: 32px;
    vertical-align: middle;
}

.flag_small {
    height: 11px;
    width: 22px;
    vertical-align: middle;
}

.menuContent {
    color: white;
    text-align: justify;
    margin-bottom: 200px;
}

.guestmapContent {
    text-align: justify;
}

.guestmapContent a:link {
    color: #CEF;
}

.guestmapContent a:visited {
    color: #8AF;
}

.modal-content {
    background-color: #D20A11;
    border-color: #D20A11;
    line-height: 5px;
}

.close {
    color: white;
    opacity: 1.0;
}

.chatMe {
    text-align: left;
    float: left;
    margin-left: 10px;
    max-width: 70%;
    padding-left: 12px;
    padding-right: 30px;
    padding-bottom: 3px;
    background-color: rgba(40, 10, 10, .75);
}

.chat_header {
    font-size: 0.75em;
}

.chat_content {
    margin-top: -8px;
    font-size: 0.9em;
    line-height: 1.6em;
}

.chatOther {
    text-align: right;
    float: right;
    margin-right: 10px;
    max-width: 70%;
    padding-left: 30px;
    padding-right: 12px;
    padding-bottom: 3px;
    background-color: rgba(10, 40, 10, .75);
}

.chatBot {
    text-align: right;
    float: right;
    margin-right: 10px;
    max-width: 70%;
    padding-left: 30px;
    padding-right: 12px;
    padding-bottom: 3px;
    background-color: rgba(80, 20, 60, .70);
}

.inputSendBtn {
    max-width: 77%;
    width: 100%;
    float: left;
    color: black;
    font-weight: 400;
    font-size: 0.9em;
}

.collapseLink {
    color: white;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 5px;
}

.client-nav-icon {
    float: left;
    font-size: x-large;
    margin-left: 6px;
}

.client-nav-text {
    text-align: left;
    padding-left: 45px;
    margin-top: 3px;
}

client-nav-link {
    margin-top: 5px;
    margin-bottom: 5px;
}

.navbar-inverse .navbar-brand {
    color: black;
}

.navbar-inverse .navbar-nav > li > a {
    color: black;
}

.accompanyingPersonContent {
    padding-left: 15px;
    padding-right: 15px;
}

.header_background {

}

#page {
    min-height: 1000px;
}

.guestmap_scroll::-webkit-scrollbar {
    display: none;
}

.row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

#powered_footer {
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    text-align: center;
    right: 0;
    padding: 5px;
    bottom: 0px;
    line-height: 1.15em;
    font-size: .7em;
    margin: 0
}

.guestmapPosition {

}

.btn_specials:disabled {
    pointer-events: none;
    outline: 0;
    opacity: .6;
    color: #fff;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.star-rating {
    margin: 0;
    padding: 0;
    display: inline-block;
}

.star {
    padding: 2px;
    color: #ddd;
    font-size: 32px;
    text-shadow: .05em .05em #aaa;
    list-style-type: none;
    display: inline-block;
    cursor: pointer;

}

.star.filled {
    color: #fd0;
}

.feedbackRating {
    font-size: 18px;
    margin-top: 30px;
}

.star.readonly .star.filled {
    color: #666;
}

#hotelChat {
    margin-bottom: 0;
}

#hotelChat #chatHistory {
    overflow: scroll;
    padding: 0 10px;
    height: 100%;
    color: white;
}

#chatInput {
    position: fixed;
    bottom: 0;
    left: 0;
    margin-left: 10px;
    width: 95%;
}

.widget-offset {
    bottom: 50px !important;
}

.splashScreenBtnBox {
    width: 100%;
    position: absolute;
    bottom: 70px;
}

.splashScreenBtn {
    display: block;
    padding: 0px;
    margin: auto;
    margin-top: 20px;
    line-height: 0px;
    height: 40px;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #ffffff;
    background-color: #D20A11 !important;
}

@media (max-width: 500px) {
    .splashScreenBtn {
        width: 80%;
    }
}

@media (min-width: 501px) {
    .splashScreenBtn {
        width: 35%;
    }
}

.splashScreenCloseBtn {
    position: absolute;
    top: 15px;
    left: 15px;
    background: #D20A11 !important;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 23px;
    padding: 0;
    margin: 0;
    z-index: 10;
}

.splashScreenClickableBg {
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 1;
}

/* Set paths for intl-tel-input flags */
.iti-flag {
    background-image: url("../lib/flags/flags.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .iti-flag {
        background-image: url("../lib/flags/flags@2x.png");
    }
}

/* Overwrite DateTimePicker styles */
.datepicker table tr th,
.datepicker table tr td {
    color: black;
}

.datepicker table tr td.active.active {
    color: white;
}

.timepicker-picker table tr td span,
.timepicker-picker table tr td.separator,
.timepicker-minutes table tr td,
.timepicker-hours table tr td {
    color: black;
}

.timepicker-picker table tr td a.btn span,
.timepicker-picker table tr td button {
    color: #428bca;
}

/* Allow angular.js to be loaded in body, hiding cloaked elements until 
templates compile.  The !important is important given that there may be 
other selectors that are more specific or come later and might alter display. */
[ng\:cloak], [ng-cloak], .ng-cloak {
    display: none !important;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.versioning {
    position: absolute;
    color: #EEEEEE;
    z-index: 5000;
    right: 0;
    margin-right: 10px;
    position: fixed;
    text-align: center;
    padding: 2px;
    top: 0;
    line-height: 1.15em;
    font-size: .7em;
    color: white;
}

/*Fix navbar above chat widget */
.navbar-fixed-bottom, .navbar-fixed-top {
    z-index: 990 !important;
}

/* Modal Settings */
@media (max-width: 374px) {
    .externalPageModal .modal-body {
        height: 450px;
    }
}

@media (min-width: 375px) {
    .externalPageModal .modal-body {
        height: 500px;
    }
}

@media (min-width: 576px) {
    .externalPageModal .modal-body {
        height: 650px;
    }
}

@media (min-width: 768px) {
    .externalPageModal .modal-body {
        height: 800px;
    }
}

.externalPageModalClose {
    color: white;
    font-size: 30px;
}

.externalPageModalIframe {
    width: 100%;
    height: 100%;
}

.row.transaction-line {
    border-bottom: 1px solid #2b303a;
    padding-bottom: 2px;
    margin-bottom: 5px;
    text-align: left;
    padding-left: 20px;
    padding-top: 10px;
}

.row.transaction-line > div {
    line-height: 18px;
    display: inline-block;
    margin: 0 15px 10px 0;
    text-align: left;
}

.transaction-label {
    font-size: 12px;
    font-style: italic;
}

.transaction-value {
    font-weight: bold;
}

.collapsible-header {
    font-size: 18px;
    font-weight: 300;
    color: #3b4559;
    line-height: 30px;
    background-color: #e19a00;
    margin-right: 10px;
}

.elevator-main *:focus {
    border: 4px solid #000;
}

.rf-label {
    font-size: 12px;
}

.rf-value {
    font-weight: bold;
    padding-bottom: 10px;
    font-size: 16px;
}

.agbText p {
    display: inline;
}

.agbCheckbox:focus:invalid {
    outline: 3px solid red;
}