.inline-select-root_backgroundWhite {
    border-color: #003BDE;
    color: #001837;
}

.inline-select-root{
    border-bottom: 0.1em solid;
    border-bottom-color: currentcolor;
    color: #FFF;
    display: inline-block;
    line-height: 1.1;
    margin: 1rem;
    position: relative;
    white-space: nowrap;
    cursor: default;
    font-size: 2rem;
}

.inline-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border-radius: 0;
    border: 0;
    color: black;
    cursor: pointer;
    font-size: 2rem;
    margin: 0;
    opacity: 0;
    outline: 0;
    padding: 0 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}

select {
    text-transform: none;
}

.inline-select-empty {
    display: inline-block;
    margin-right: 1rem;
}

.inline-select-arrow {
    font-size: 10px;
    vertical-align: middle;
}
