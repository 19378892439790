/* SELF CHECKIN */
.selfCheckinBackground {
    background-color: rgb(125, 125, 125);
    background-blend-mode: screen;
}

.selfCheckinLoadingArea {
    margin-top: 50px;
}

.selfCheckinContent {
    background: transparent;
    color: black;
    font-size: 20px;
    line-height: 22px;
}

.selfCheckinContent input[type=checkbox] {
    -ms-transform: scale(2) !important;
    -moz-transform: scale(2) !important;
    -webkit-transform: scale(2) !important;
    -o-transform: scale(2) !important;
    padding: 10px;
    margin-right: 10px;
}

.selfCheckinHeaderArea {
    text-align: center;
    margin-bottom: 20px;
}

.selfCheckinPages {
    float: right;
}

.selfCheckinCancel {
    font-size: 14px;
    font-style: italic;
    cursor: pointer;
}

.selfCheckinCancel:hover {
    text-decoration: underline;
}

.selfCheckinHeader {
    font-size: 50px;
    line-height: 55px;
}

.selfCheckinSubViewHeader {
    font-size: 38px;
    line-height: 40px;
    font-weight: normal;
}

.selfCheckinSubSubViewHeader {
    font-size: 26px;
    line-height: 28px;
    font-weight: normal;
}

.selfCheckinDescription {
    margin-bottom: 25px;
}

#selfCheckinBannerImage {
    position: absolute;
    top: 0;
    left: 0;
}

.selfCheckinBtn {
    background: rgb(210, 10, 17) !important;
    color: white !important;
}

/* Prevents waiting for finished animation loop and speeds up ng-hide */
.selfCheckinBtn.ng-hide.ng-hide-animate {
    display: none !important;
}

.selfCheckinFlag {
    cursor: pointer;
    -webkit-box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.75);
    width: 250px;
}

.selfCheckinFlagLeft {
    text-align: right;
    padding-right: 30px;
}

.selfCheckinFlagRight {
    text-align: left;
    padding-left: 30px;
}

.selfCheckinForm {
    text-align: left;
}

.selfCheckinForm textarea {
    height: 80px !important;
    resize: none;
}

.selfCheckinForm .form-control {
    height: 40px;
}

[ng-switch-when="addressInfo"] .form-group {
    margin-bottom: 0;
}

.selfCheckinCheckboxError {
    -webkit-box-shadow: 0 0 7px 6px rgba(200, 68, 66, .75);
    -moz-box-shadow: 0 0 7px 6px rgba(200, 68, 66, .75);
    box-shadow: 0 0 7px 6px rgba(200, 68, 66, .75);
}

.selfCheckinTable,
.selfCheckinInvoiceTable {
    table-layout: fixed;
    font-size: 15px;
    border-color: black;
}

.selfCheckinTable > thead > tr > th,
.selfCheckinTable > tbody > tr > td {
    border: none;
}

.selfCheckinInvoiceTable > thead > tr > th,
.selfCheckinInvoiceTable > tbody > tr > td {
    border-bottom-color: black;
    border-top-color: black;
}

.selfCheckinPagination {
    margin: 0;
}

.selfCheckinPagination > li > a {
    color: #000 !important;
    background-color: transparent !important;
    margin: 0 10px;
}

.selfCheckinPagination > li.active > a,
.selfCheckinPagination > li.active > span {
    border-radius: 25% !important;
    border: 1px solid #999 !important;
}

.selfCheckinRoomNumberContainer {
    margin: 30px 0;
}

.selfCheckinRoomNumber {
    font-size: 80px;
    line-height: 170px;
    font-weight: bold;
    padding: 15px 20px;
    border: 2px solid #777;
    border-radius: 20px;
    background-color: #eee;
}

.selfCheckinList {
    text-align: left;
    display: table;
    margin: auto;
}

.selfCheckinList > li {
    margin: 8px 0;
}

.selfCheckinListItem {
    margin-left: 10px;
}

.selfCheckinAnotherKeyBtn {
    margin-top: 80px !important;
}

.selfCheckinFooterArea {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.selfCheckinFooterArea .progress {
    height: 25px;
}

.selfCheckinFooterArea .progress-bar {
    line-height: 25px;
    font-size: 15px;
    font-weight: bold;
    background-color: rgb(210, 10, 17);
}

.selfCheckinFooterBtnAreaLeft {
    text-align: left;
}

.selfCheckinFooterBtnAreaRight {
    text-align: right;
}

.selfCheckinSpacerXLarge {
    margin-top: 40px;
}

.selfCheckinSpacerLarge {
    margin-top: 40px;
}

.selfCheckinSpacerSmall {
    margin-top: 20px;
}

.selfCheckinSpacerXSmall {
    margin-top: 10px;
}

/* PRINT: REGISTRATION CARD */
.scpContent {
    color: black;
    text-align: left;
    font-size: 10px;
    line-height: 12px !important;
    /* Uncomment if the printer is not able to print the full range*/
    /*width: 95%;*/
    /*padding-left: 2.5%;*/
}

.scpHeaderLogo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: yellow;
    text-align: center;
}

.scpHeaderLogo > img {
    width: 150px;
}

.scpHeaderArea {
    width: 100%;
    margin-top: 20px;
    height: 55px;
}

.scpHeaderText {
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
    float: right
}

.scpAreaHeader.smallHeader {
    font-size: 12px;
    margin-bottom: 3px;
    margin-top: 5px
}

.scpAreaHeader,
.scpClientAreaHeader {
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 10px;
}

.scpAreaHeader {
    color: rgb(210, 10, 17) !important;
    margin-top: 15px;
}

.scpClientAreaHeader {
    color: rgb(250, 182, 0) !important;
    margin-top: 10px;
}

.scpCheckbox {
    font-size: 14px;
    margin-right: 5px;
}

.scpTable,
.scpTableBorderless {
    width: 100%;
}

.scpTable {
    margin-bottom: 10px !important;
}

.scpTable td {
    border: 1px solid black;
    padding: 2px 5px;
    vertical-align: top;
}

.scpTableNoBorderBottom td {
    border-bottom: none !important;
}

.scpTableNoBorderTop td {
    border-top: none !important;
}

.scpTable tr.scpSingleLine {
    height: 40px;
}

.scpTable tr.scpDoubleLine {
    height: 70px;
}

.scpTable tr.scpMultiLine {
    height: 95px;
}

.scpTable .scpQuarter {
    width: 25%;
}

.scpTable .scpHalf {
    width: 50%;
}

.scpTableHeader {
    font-weight: 600;
}

.scpTableBorderless {
    border-collapse: collapse;
    empty-cells: show;
}

tr.strikeout td {
    position: relative;
}

tr.strikeout td:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: 1px solid #111;
    width: 100%;
}

tr.strikeout td:after {
    content: "\00B7";
    font-size: 1px;
}

.scpSignHereText {
    font-weight: bold;
    font-size: 14px;
}

.scpSignHereLine {
    margin: 0;
    font-weight: bold;
    float: right;
}

/* Fix ugly style of select input in firefox */
.select {
    display: inline-block;
    position: relative;
    height: 40px;
    width: 100%;
    border-radius: 4px;
    margin-right: 2px
}

.select:before,
.select:after {
    content: " ";
    position: absolute;
    pointer-events: none;
}

.select:before {
    width: 16px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: white;
    -webkit-border-top-right-radius: 50%;
    -moz-border-radius-topright: 50%;
    border-top-right-radius: 50%;
    -webkit-border-bottom-right-radius: 50%;
    -moz-border-radius-bottomright: 50%;
    border-bottom-right-radius: 50%;
    z-index: 10;
}

.select:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #666;
    right: .3em;
    top: 50%;
    z-index: 11;
}

.select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    box-shadow: none;
    border: none;
    outline: none;
    padding: 5px 10px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #888;
}

.select select::-ms-expand {
    display: none;
}
