
.form-control2 {
    width:49%;
    min-width: 45px;

}
.form-control3 {
    width:33%;
    min-width: 30px;

}
input[type="text"],
input[type="number"],
input[type="date"],
input[type="time"], 
select,
textarea, 
textarea.form-control {

    height: 40px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding: 0 12px;
    vertical-align: middle;
    background: #fff;
    border: 1px solid #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    color: #888;
    margin-bottom: 15px;
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
    -o-transition: all .3s; -moz-transition: all .3s; -webkit-transition: all .3s; -ms-transition: all .3s; transition: all .3s;
}

.formlabel
{
    font-weight:300;
    color: #fff;
}


textarea, 
textarea.form-control {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 30px;
}

input[type="text"]:focus, 
textarea:focus, 
textarea.form-control:focus {
    outline: 0;
    background: #fff;
    border: 3px solid #fff;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

input[type="text"]:-moz-placeholder, textarea:-moz-placeholder, textarea.form-control:-moz-placeholder { color: #888; }
input[type="text"]:-ms-input-placeholder, textarea:-ms-input-placeholder, textarea.form-control:-ms-input-placeholder { color: #888; }
input[type="text"]::-webkit-input-placeholder, textarea::-webkit-input-placeholder, textarea.form-control::-webkit-input-placeholder { color: #888; }



button.btn {
    height: 50px;
    margin: 0;
    padding: 0 20px;
    vertical-align: middle;
    background: #19b9e7;
    border: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 50px;
    color: #fff;
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
    text-shadow: none;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
    -o-transition: all .3s; -moz-transition: all .3s; -webkit-transition: all .3s; -ms-transition: all .3s; transition: all .3s;
}

button.btn:hover { opacity: 0.6; color: #fff; }

button.btn:active { outline: 0; opacity: 0.6; color: #fff; -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; }

button.btn:focus { outline: 0; opacity: 0.6; background: #19b9e7; color: #fff; }

button.btn:active:focus, button.btn.active:focus { outline: 0; opacity: 0.6; background: #19b9e7; color: #fff; }

@media (max-width: 992px) {
    .container {
        padding-left:3px;
        padding-right:3px;
        
    }
    div[class*="col-"]{padding-left:3px; padding-right:px;}

}

@media (max-width: 768px) {
    .container {
        padding-left:2px;
        padding-right:2px;
        margin:0;
    }
    div[class*="col-"]{padding-left:2px; padding-right:2px;}

}


@media (max-width: 415px) {
    .container {
          padding-left:1px;
        padding-right:1px;
        margin:0;
    }

    div[class*="col-"]{padding-left:1px; padding-right:1px;}
}


/* intl-tel-input settings */
.intl-tel-input {
    width: 90%;
}
.intl-tel-input input {
    width: 100%;
}
.intl-tel-input .country-name {
    color: black;
}