/*body.slide-active{overflow-x:hidden}
.no-margin-top{margin-top:0!important}
#page-content{position:relative;padding-top:70px;left:0}
#page-content.slide-active{padding-top:0}
#slide-nav .navbar-toggle{cursor:pointer;position:relative;line-height:0;float:left;margin:0;width:30px;height:40px;padding:10px 0 0;border:0;background:0 0}
#slide-nav .navbar-toggle>.icon-bar{width:100%;display:block;height:3px;margin:5px 0 0}
#slide-nav .navbar-toggle.slide-active .icon-bar{background:orange}
.navbar-header{position:relative}
.nav-item{text-align:left}
.navbar.navbar-fixed-top.slide-active{position:relative}@media (max-width:767px){#page-content{padding-top:55px}
.menu-icon{padding:10px}
.onavbar-icon{font-size:large;text-align:center;float:left}
.onavbar-text{text-align:left;margin-top:9px}
#slide-nav .container{margin:0;padding:0!important}
#slide-nav .navbar-header{margin:0 auto;padding:0 15px}
#slide-nav .navbar.slide-active{position:absolute;width:80%;top:-1px;z-index:1000}
#slide-nav #slidemenu{background:#f7f7f7;left:-100%;width:80%;min-width:0;position:absolute;padding-left:0;z-index:2;top:-8px;margin:0}
#slide-nav #slidemenu .navbar-nav{min-width:0;width:100%;margin:0}
#slide-nav #slidemenu .navbar-nav .dropdown-menu li a{min-width:0;width:80%;white-space:normal}
#slide-nav{border-top:0}#slide-nav.navbar-inverse #slidemenu{background:#333}
#slide-nav #navbar-height-col{position:fixed;top:0;height:100%;width:80%;left:-80%;background:#eee}
#slide-nav.navbar-inverse #navbar-height-col{background:#333;z-index:1;border:0}
#slide-nav .navbar-form{width:100%;margin:8px 0;text-align:center;overflow:hidden}
#slide-nav .navbar-form .form-control{text-align:center}
#slide-nav .navbar-form .btn{width:100%}}@media (min-width:768px){#page-content{padding-top:85px}
.navbar-brand{margin-bottom:-20px}.menu-icon{padding:8px}
.onavbar-icon{font-size:large;text-align:center;float:left;margin-left:10px}
.onavbar-text{text-align:center;float:right;margin:5px 10px 15px 5px}
#page-content{left:0!important}
.navbar.navbar-fixed-top.slide-active{position:fixed}
.navbar-header{left:0!important}}
body{font-family:Roboto,sans-serif;font-weight:300;color:#888;line-height:30px;text-align:center;background-image:url(../assets/img/backgrounds/1.jpg);background-position:50%;background-repeat:no-repeat;background-size:cover;height:100%}strong{font-weight:500}
a,a:focus,a:hover{color:#19b9e7;text-decoration:none;-o-transition:all .3s;-moz-transition:all .3s;-webkit-transition:all .3s;-ms-transition:all .3s;transition:all .3s}
h1,h2{margin-top:10px;font-size:38px;font-weight:100;color:#555;line-height:50px}
h3{font-size:22px;font-weight:300;color:#555;line-height:30px}
img{max-width:100%}
.medium-paragraph{font-size:18px;line-height:32px}
.blue{color:#19b9e7}
::-moz-selection{background:#19b9e7;color:#fff;text-shadow:none}
::selection{background:#19b9e7;color:#fff;text-shadow:none}
.btn-link-1{display:inline-block;height:50px;margin:0 5px;padding:16px 20px 0;background:#19b9e7;font-size:16px;font-weight:300;line-height:16px;color:#fff;-moz-border-radius:4px;-webkit-border-radius:4px;border-radius:4px}
.btn-link-1:active,.btn-link-1:focus,.btn-link-1:hover{outline:0;opacity:.6;color:#fff}
.btn-link-2{display:inline-block;height:50px;margin:0 5px;padding:15px 20px 0;background:rgba(0,0,0,.3);border:1px solid #fff;font-size:16px;font-weight:300;line-height:16px;color:#fff;-moz-border-radius:4px;-webkit-border-radius:4px;border-radius:4px}
.btn-link-2:active,.btn-link-2:active:focus,.btn-link-2:focus,.btn-link-2:hover{outline:0;opacity:.6;background:rgba(0,0,0,.3);color:#fff}
.inner-bg{padding:30px 0 150px}
.top-content .text{padding-bottom:20px;color:#fff}
.top-content .text h1{color:#fff}
.top-content .description{margin:20px 0 10px}
.top-content .description p{opacity:.8}
.top-content .description a{color:#fff}
.top-content .description a:focus,.top-content .description a:hover{border-bottom:1px dotted #fff}
.top-content .top-big-link{margin-top:35px}
.top-content .book img{-moz-border-radius:4px;-webkit-border-radius:4px;border-radius:4px}
.top-content .form-box{padding-top:20px}
.form-top{overflow:hidden;padding:0 25px 15px;background:#444;background:rgba(0,0,0,.35);-moz-border-radius:4px 4px 0 0;-webkit-border-radius:4px 4px 0 0;border-radius:4px 4px 0 0;text-align:left}
.form-top-left{float:left;width:75%;padding-top:25px}
.form-top-left h3{margin-top:0;color:#fff}
.form-top-left p{opacity:.8;color:#fff}
.form-top-right{float:left;width:25%;padding-top:5px;font-size:66px;color:#fff;line-height:100px;text-align:right;opacity:.3}
.form-bottom{padding:25px 25px 30px;background:#444;background:rgba(0,0,0,.3);-moz-border-radius:0 0 4px 4px;-webkit-border-radius:0 0 4px 4px;border-radius:0 0 4px 4px;text-align:left}
.form-bottom form textarea{height:100px}
.form-bottom form .input-error{border-color:#19b9e7}
@media (max-width:767px){
    .inner-bg{padding:30px 0 110px}
    .top-content .top-big-link{margin-top:25px}
    .top-content .top-big-link a.btn{margin-top:10px}
    .top-content .form-box{padding-top:20px}
}
@media (max-width:415px){h1,h2{font-size:32px}}
.history_bar{background-color:rgba(255,255,255,.6);font-size:small;padding-top:25px;padding-bottom:35px;margin:10px 2%;width:95%;float:left}
.kachel_big{background-color:rgba(255,255,255,.65);font-size:small;padding-top:25px;padding-bottom:35px;margin:10px 2%;width:95%;float:left}
.kachel_small{background-color:rgba(255,255,255,.65);font-size:small;padding-top:25px;padding-bottom:35px;margin:10px 1% 10px 2%;width:46%;float:left}
.kachel-icon{padding:10px;font-size:xx-large}
.kachel-table{padding-bottom:800px}
.form-control2{width:49%;min-width:45px}
.form-control3{width:33%;min-width:30px}

input[type=text],textarea,textarea.form-control{height:40px;margin:0 0 15px;padding:0 3px;vertical-align:middle;background:#fff;border:1px solid #fff;font-family:Roboto,sans-serif;font-size:16px;font-weight:300;line-height:40px;color:#888;-moz-border-radius:4px;-webkit-border-radius:4px;border-radius:4px;-moz-box-shadow:none;-webkit-box-shadow:none;box-shadow:none;-o-transition:all .3s;-moz-transition:all .3s;-webkit-transition:all .3s;-ms-transition:all .3s;transition:all .3s}
.formlabel{font-weight:300;color:#fff}textarea,textarea.form-control{padding-top:10px;padding-bottom:10px;line-height:30px}
input[type=text]:focus,textarea.form-control:focus,textarea:focus{outline:0;background:#fff;border:3px solid #fff;-moz-box-shadow:none;-webkit-box-shadow:none;box-shadow:none}
input[type=text]:-moz-placeholder,textarea.form-control:-moz-placeholder,textarea:-moz-placeholder{color:#888}
input[type=text]:-ms-input-placeholder,textarea.form-control:-ms-input-placeholder,textarea:-ms-input-placeholder{color:#888}
input[type=text]::-webkit-input-placeholder,textarea.form-control::-webkit-input-placeholder,textarea::-webkit-input-placeholder{color:#888}
button.btn{height:50px;margin:0;padding:0 20px;vertical-align:middle;background:#19b9e7;border:0;font-family:Roboto,sans-serif;font-size:16px;font-weight:300;line-height:50px;color:#fff;-moz-border-radius:4px;-webkit-border-radius:4px;border-radius:4px;text-shadow:none;-moz-box-shadow:none;-webkit-box-shadow:none;box-shadow:none;-o-transition:all .3s;-moz-transition:all .3s;-webkit-transition:all .3s;-ms-transition:all .3s;transition:all .3s}button.btn:hover{opacity:.6;color:#fff}button.btn:active{outline:0;opacity:.6;color:#fff;-moz-box-shadow:none;-webkit-box-shadow:none;box-shadow:none}button.btn.active:focus,button.btn:active:focus,button.btn:focus{outline:0;opacity:.6;background:#19b9e7;color:#fff}*/

.usedfield{background:#aab4be!important}
.ng-submitted .ng-invalid {
	background-color: rgb(255, 150, 150) !important;
}
::-webkit-scrollbar {
    display: none;
}

.pagination{
	padding-left: 4%;
	padding-right: 2%;
}

.pagination>.active>a,.pagination>.active>span,.pagination>.active>a:hover,.pagination>.active>span:hover,.pagination>.active>a:focus,.pagination>.active>span:focus {
    z-index: 2;
    cursor: default;
    background-color: #fff;
    border-color:none!important;
}

.pagination>li>a,.pagination>li>span {
    position: relative;
    float: left;
		font-size: inherit;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #FFF;
    text-decoration: none;
    background-color: transparent;
    border:none!important;
}
.pagination>.disabled>span,.pagination>.disabled>span:hover,.pagination>.disabled>span:focus,.pagination>.disabled>a,.pagination>.disabled>a:hover,.pagination>.disabled>a:focus {
		color: #FFF;
		cursor: not-allowed;
    border-color: #ddd;
		background-color: transparent;
}

.pagination>.active>a,.pagination>.active>span,.pagination>.active>a:hover,.pagination>.active>span:hover,.pagination>.active>a:focus,.pagination>.active>span:focus {
    z-index: 2;
    color:#2a6496;
    cursor: default;
    background-color:#fff;
    border-color:none!important;
}
.pagination>li>a:hover,.pagination>li>span:hover,.pagination>li>a:focus,.pagination>li>span:focus {
    color: #2a6496;
    background-color: #fff;
    border-color: #ddd
}
.opt
{
    border-bottom:1px solid #333;
}
.red::-webkit-input-placeholder {
		color: red!important;
		font-style: italic;
}

.black {
		font-style: normal;
}
@media (max-width: 576px) {
.col-xs-10.mine{
    padding-top:20px;
}
}

@media (min-width: 576px){
	.col-md-1.agb_checkbox{
		width: 1%;
		padding-left: 0px;
	}
	.col-md-1.agb_text{
		    padding-right: 0px;
	}
}

a.a_orig {
    color: #428bca;
		font-weight: bold;
    text-decoration: none
}

a.a_orig:hover,a.a_orig:focus {
    color: #2a6496;
		font-weight: bold;
    text-decoration: underline
}

a.a_orig:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
		font-weight: bold;
    outline-offset: -2px
}

.checkoutBtn {
	background-color: #D20A11!important;
	color: #FFF!important;
}
